<template>
    <b-modal ref="modalSearchWeatherbys" size="xxl" hide-footer>
        <template v-slot:modal-title>
            {{ $t("action.rapprochement_weatherbys") }}
        </template>
        <SearchWeatherbys from="modal" :horseToAddName="horse_data.horse_nom" :compared_horse="horse_data" @updated="rapproachHorse" />
    </b-modal>
</template>

<script type="text/javascript">

import _debounce from 'lodash/debounce'
import Horse from "@/mixins/Horse.js";

export default {
    name: "ModalSearchWeatherbys",
    mixins: [Horse],
    props: ['horse'],
    data () {
        return {
            horse_data: {},
        }
    },
    methods: {
        openModal(horse = null){
            if(horse){
                this.horse_data = horse
            } else {
                this.horse_data = this.horse
            }
            this.$refs.modalSearchWeatherbys.show()
        },
        rapproachHorse(){
            this.$emit('updated')
            this.$refs.modalSearchWeatherbys.hide()
        },
    },
    components: {
        CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
        LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
        SearchWeatherbys: () => import('@/components/Horse/Ajout/SearchWeatherbys')
    }
}
</script>